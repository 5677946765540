import React, { useState, useEffect } from "react"

 const IPhoneDetect =  () => {
  const [iPhone, setiPhone] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    console.log(userAgent);
      
    const i = Boolean(
      userAgent.match(
        /iPhone|iPad/i
      )
    );
    setiPhone(i);
  }, []);

  return { iPhone };
}

export default IPhoneDetect;