import React from "react"
import "./../styles.scss"
import "./footer.scss"
import { Link } from "gatsby"
import social_facebook from '../../content/assets/footer/social_facebook_fill.svg'
import social_instagram from '../../content/assets/footer/social_instagram_fill.svg'
import social_linkedin from '../../content/assets/footer/social_linkedin_fill.svg'
import social_youtube from '../../content/assets/footer/social_youtube_fill.svg'
import SolvLogo from '../assets/solvLogo.svg'

const Footer = () => {
  
  const data = [
    {
      id: 1,
      icon: <img className="social-icon" src={social_youtube} alt="icon social youtube"/>,
      url: "https://www.youtube.com/c/SolvIndia",
    },
    {
      id: 2,
      icon: <img className="social-icon" src={social_linkedin} alt="icon social linkedin"/>,
      url: "https://www.linkedin.com/company/solv-india",
    },
    {
      id: 3,
      icon: <img className="social-icon" src={social_facebook} alt="icon social facebook"/>,
      url: "https://www.facebook.com/solvezy",
    },
    // {
    //   id: 4,
    //   icon: <img className="social-icon" src={social_twitter} />,
    //   url: "https://www.twitter.com",
    // },
    {
      id: 4,
      icon: <img className="social-icon" src={social_instagram} alt="icon social instagram"/>,
      url: "https://www.instagram.com/solv_india/",
    },
  ]
  return (
    <div className='solv-footer solv-flex-row solv-width-100'>
      <div className='solv-flex-column solv-footer-coloumn-1'>
        <img className='footer-solv-logo' src={SolvLogo} />
        {/* <img className='footer-sc-logo' src={SCLogo} /> */}
      </div>
      <div className='solv-footer-links solv-flex-row solv-width-100'>
        <div className='solv-flex-column solv-footer-coloumn-2'>
          <ul className='list-without-bullet'>
            <li>
              <Link to="/about-us/">
                    About us
              </Link>
            </li>
            <li>
              <Link to="/ecommerce/">
                  E-Commerce
              </Link>
            </li>
            <li>
              <Link to="/services/financial-services">
                  Finance
              </Link>
            </li>
            {/* <li>
              <Link to="/services/business-services">                  
                   Services
              </Link>
            </li> */}
            <li>
              <Link to="/careers/">
                  Careers
              </Link>
            </li>
            <li>
              <Link to="/media/press">
                  Press
              </Link>
            </li>
            <li>
              <Link to="/communications/">
              Communications
              </Link>
            </li>
            {/* <li>
              <Link to="/communications/">
              Communications
              </Link>
            </li> */}
            {/* <li>
              <Link to="/">
                  Blog
              </Link>
            </li> */}
          </ul>
        </div>
        <div className='solv-flex-column solv-footer-coloumn-3'>
          <ul className='list-without-bullet'>
            {/* <li><span style={{'fontFamily': 'Inter-Bold'}}>Media Kit</span>&nbsp;<span className='font-color-orange'>Download</span></li> */}
            {/* <li style={{'paddingTop': "28px"}}><span style={{'fontFamily': 'Inter-Bold'}}>Contact Us</span></li>*/}
            <li ><span style={{'fontFamily': 'Inter-Bold'}}>Contact Us</span></li>
            <li style={{'padding': "8px 0"}}>+91 9108191081</li>
            <li className='font-color-orange lower'><a href = "mailto: cs@solvezy.com">cs@solvezy.com</a></li>
            <li style={{'padding': "8px 0"}}>Indiqube Edge, Bellandur, Bengaluru, Karnataka, 560103</li>
            <li style={{'padding': "8px 0"}}>{data.map(link => {
                return (
                  <span key={link.id}>
                    <a href={link.url} target="_blank" className="social-link">
                      {link.icon}
                    </a>
                  </span>
                )
              })
            }</li>
            
          </ul>
        </div>
        <div className='solv-flex-column solv-footer-coloumn-4'>
            <div className='registerSCR'>Registered As Standard Chartered Research And Technology India Pvt. Ltd.</div>
            <div className='registerSCR'>CIN No: U66000KA2019FTC120109</div>
          <ul className='list-without-bullet'>
           
            <div style={{'margin': '12px 0px'}}>
            <li>
              <Link className='links' to="/terms-of-services/">
                Terms of Services
              </Link>
            </li>
            <li className='links'>
              <Link className='links' to="/privacy-policy/">
                Privacy Policy
            </Link>
            </li>
            <li className='links'>
              <Link className='links' to="/information-security-policy/">
                Information Security Policy
            </Link>
            </li>
            <li className='links'>
              <Link className='links' to="/posh-policy/">
                POSH Policy
            </Link>
            </li>
            </div>
            {/* <li className='font-color-blue' style={{'paddingTop': "20px"}}>Copyright © 2021 All Rights Reserved</li> */}
          </ul>
            <div className='font-color-blue'>Copyright © 2021 All Rights Reserved</div>
            <div><span className='solv-disply-none'>.</span></div>
        </div>
      </div>
    </div>
  )
}

export default Footer