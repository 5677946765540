import React, { useState, useEffect } from "react"
import "./newnavbar.scss"
import logo from "../assets/solvLogo.svg"
import googleplay from "../../static/googleplay.png"
import arrow from "../assets/navIcons/rightArrow.svg"
import close from "../assets/navIcons/close.svg"
import { Link, navigate } from "gatsby"
import { closeContext } from "./../context/provider"

const Newnavbar = props => {
  const [active, setActive] = useState("")
  const [hovered, setHovered] = useState(false)
  const toggleHover = hovered => setHovered(hovered)
  const [subMenu, setsubMenu] = useState(true)

  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    if (
      window.location.href.includes("/about") ||
      window.location.href.includes("/ecommerce")
    ) {
    } else if (
      window.location.href.includes("/financial-services") ||
      window.location.href.includes("/business-services") ||
      window.location.href.includes("/careers")
    ) {
      var elementToNavigate = document.getElementById("careers")
      if (elementToNavigate) elementToNavigate.scrollIntoView()
    } else if (
      window.location.href.includes("/press") ||
      window.location.href.includes("/contact")
    ) {
      var elementToNavigate = document.getElementById("contact")
      if (elementToNavigate) elementToNavigate.scrollIntoView()
    }

    setHasMounted(true)
  }, [])

  const width =
    typeof window !== "undefined"
      ? window?.innerWidth ||
        document?.documentElement.clientWidth ||
        document?.body.clientWidth
      : 501

  const isWeb = width > 500

  function hideNavBar(state) {
    if (!isWeb) {
      if(props.isIphone){
        navigate("/services/business-services/");
      }
      if (state) {
        setsubMenu(true)
      } else {
        setsubMenu(false)
      }
    }
  }

  function handleClickGooglePlay() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.solv.commerce&referrer=utm_source%3Dwebsite%26utm_medium%3DhomePageCtaButton%26utm_term%3Dinstall"
    )
  }

  const location = typeof window !== "undefined" ? window.location.href : ""

  return (
    <closeContext.Consumer>
      {context => (
        <React.Fragment>
          <nav
            className={`navbar ${props.scrolled || hovered ? "scrolled" : ""}`}
            onMouseOver={() => toggleHover(true)}
            onMouseOut={() => toggleHover(false)}
          >
            <div className="nav-center">
              <div className="nav-header">
                <Link to="/">
                  <img className="solv-logo" src={logo} alt="logo" />
                  {/* <img className="sc-logo" src={logo2} alt="logo2" /> */}
                </Link>
              </div>

              {/* {props.hideNav ? (
                <></>
              ) : ( */}
              <section className="section section--menu" id="Prospero">
                <nav className="menu menu--prospero">
                  <ul className="menu__list">
                    <div className="homeHide">
                      <li
                        className={[
                          "menu__item",
                          props.url == "/" ? "menu__item--current" : "",
                          location == "https://pp-site.solvezy.net/"
                            ? "menu__item--current"
                            : "",
                          location == "https://solvezy.com/"
                            ? "menu__item--current"
                            : "",
                          !hasMounted ? "menu__item--current" : "",
                        ].join(" ")}
                      >
                        <Link
                          className="menu__link"
                          to="/"
                          activeClassName="menu__item--home"
                        >
                          Home
                        </Link>
                      </li>
                    </div>

                    <li
                      className={[
                        "menu__item",
                        props.url.includes("about-us")
                          ? "menu__item--current"
                          : "",
                      ].join(" ")}
                    >
                      <Link
                        className="menu__link"
                        to="/about-us/"
                        activeClassName="menu__item--current"
                        //   onClick={() => onClick("about")}
                        id="about"
                      >
                        About Us
                      </Link>
                    </li>
                    <li
                      className={[
                        "menu__item",
                        props.url.includes("ecommerce")
                          ? "menu__item--current"
                          : "",
                      ].join(" ")}
                    >
                      <Link
                        id="commerce"
                        className="menu__link"
                        to="/ecommerce/"
                        //   onClick={() => onClick("ecom")}
                      >
                        E-Commerce
                      </Link>
                    </li>
                    <li
                      className={[
                        "menu__item",
                        props.url.includes("financial-services")
                          ? "menu__item--current"
                          : "",
                      ].join(" ")}
                    >
                      <Link
                        id="finance"
                        className="menu__link"
                        to="/services/financial-services/"
                        //   onClick={() => onClick("ecom")}
                      >
                        Finance
                      </Link>
                    </li>

                    {/* <li
                        className={[
                          "menu__item",
                          props.url.includes("business-services")
                            ? "menu__item--current"
                            : "",
                        ].join(" ")}
                      >
                        <Link
                          className="menu__link"
                          to="/services/business-services/"
                          //   onClick={() => onClick("ecom")}
                        >
                          Business Services
                        </Link>
                      </li> */}

                    {/* //Below is menu section with submenu  */}
                    {/* <li
                      className={[
                        "menu__item mega__menu",
                        props.url.includes("business-services")
                          ? "menu__item--current"
                          : "",
                      ].join(" ")}
                      onClick={() => hideNavBar(false)}
                    >
                      <div
                        className="menu__link"
                        onClick={() => hideNavBar(false)}
                      >
                        Services
                      </div>
                      {isWeb ? (
                        <ul className="sub__menu__list">
                          <li
                            className="sub__menu__item"
                          >
                            <Link
                              id="business"
                              className=""
                              to="/services/business-services/"
                            >
                              <div className="purple">
                                <div className="info solvSureIcon">
                                  <div className="title">Solv Sure</div>
                                  <div className="title"> &gt;</div>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      ) : subMenu ? (
                        <></>
                      ) : (
                        <ul
                          className="sub__menu__list"
                          style={{
                            visibility: "visible",
                            opacity: "1",
                            display: "flex",
                          }}
                        >
                          <li
                            className="sub__menu__item"
                          >
                            <Link
                              id="business"
                              className=""
                              to="/services/business-services/"
                            >
                              <div className="purple">
                                <div className="info solvSureIcon">
                                  <div className="title">Solv Sure</div>
                                  <div className="title"> &gt;</div>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li> */}

                    <li
                      className={[
                        "menu__item",
                        props.url.includes("careers") ||
                        props.url.includes("Job")
                          ? "menu__item--current"
                          : "",
                      ].join(" ")}
                    >
                      <Link
                        id="careers"
                        className="menu__link"
                        to="/careers/"
                        //   onClick={() => onClick("career")}
                      >
                        Careers
                      </Link>
                    </li>
                    <li
                      className={[
                        "menu__item",
                        props.url.includes("press")
                          ? "menu__item--current"
                          : "",
                      ].join(" ")}
                    >
                      <Link
                        id="press"
                        className="menu__link"
                        to="/media/press"
                        //   onClick={() => onClick("career")}
                      >
                        Press
                      </Link>
                    </li>

                    <li
                      className={[
                        "menu__item",
                        props.url.includes("contact")
                          ? "menu__item--current"
                          : "",
                      ].join(" ")}
                    >
                      <Link
                        id="contact"
                        className="menu__link"
                        to="/contact/"
                        //   onClick={() => onClick("contact")}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className="menu__item google-play-containter">
                      <a onClick={handleClickGooglePlay}>
                        <img
                          className="google-play"
                          src={googleplay}
                          alt="gplay cta"
                        />
                        <div className="google-icon-title">Only in India</div>
                      </a>
                    </li>
                  </ul>
                </nav>
              </section>
              {/* )} */}
            </div>
            <div
              className={`nav__mobile ${context.isClosed ? "" : "hideGPlay"}`}
            >
              {(context.isClosed && !props.isIphone) ? (
                <>
                  <div className="downloadText">
                    Download the app
                    <img src={arrow} alt="arrow icon" />
                  </div>
                  <div>
                    <a href="https://play.google.com/store/apps/details?id=com.solv.commerce&referrer=utm_source%3Dwebsite%26utm_medium%3DheaderMobile%26utm_term%3Dinstall">
                      <img
                        className="google-play"
                        src={googleplay}
                        alt="gplay cta"
                      />
                    </a>
                  </div>
                  <div
                    className="close-icon"
                    onClick={() => context.changeState()}
                  >
                    <img src={close} alt="close icon" />
                  </div>
                </>
              ) : (
                <></>
                // <div
                //   className={`${
                //     props.scrolled ? "scrollLogoOut" : "scrollLogoIn"
                //   }`}
                // >
                //   <Link to="/">
                //     <img className="solv-logo" src={logo} alt="logo" />
                //   </Link>
                // </div>
              )}
              {/* {props.isIphone && (
                <div
                  className={`${
                    props.scrolled ? "scrollLogoOut" : "scrollLogoIn"
                  }`}
                >
                  <Link to="/">
                    <img className="solv-logo" src={logo} alt="logo" />
                  </Link>
                </div>
              )} */}
            </div>
          </nav>
        </React.Fragment>
      )}
    </closeContext.Consumer>
  )
}

export default Newnavbar
