import React, { useState, useEffect } from "react"
import Newnavbar from "./newnavbar"
import Footer from "./footer"
import { InView } from "react-intersection-observer"
import IPhoneDetect from "./../hooks/iphoneDetect"
import { navigate } from "gatsby"
import logo from "../assets/solvLogo.svg"
import styles from "./layout-css-module.module.scss"

const Layout = ({ children }) => {
  const { isIphone} = IPhoneDetect();
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  const width =
    typeof window !== "undefined"
      ? window?.innerWidth ||
        document?.documentElement.clientWidth ||
        document?.body.clientWidth
      : 501

  const isWeb = width > 500

  function hideNavBar(state) {
    if(!isWeb) {
      if (state) {
        sethideNav(true)
      } else {
        sethideNav(false)
      }
    }
  }

  // determined if page has scrolled to top
  const [scrolled, setScrolled] = useState(false)
  const [hideNav, sethideNav] = useState(false)
  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 30
      if (isScrolled !== scrolled) {
        console.log(`scroll ${scrolled}`)
        setScrolled(!scrolled)
      }
    }
    // add native listeners & clean up below
    document.addEventListener("scroll", handleScroll, { passive: true })

    // clean up the event handler when the component unmounts
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  function goHome() {
    console.log('navigate home')
    navigate("/")
  }

  return (
    <>
      {/* <Navbar scrolled={scrolled} /> */}
      <Newnavbar scrolled={scrolled} url={url} hideNav={hideNav} isIphone={isIphone}/>
      <div className={styles.container}>        
        <div className={styles.solvLogoContainer} onClick={goHome}>
              <img className={styles.solvLogo} src={logo} alt="logo" />
        </div>
        {children}
      </div>
      {/* <InView as="div" threshold="0.1" onChange={inView => hideNavBar(inView)}> */}
        <Footer />
      {/* </InView> */}
    </>
  )
}
export default Layout

// const Layout = ({ location, title, children }) => {
//   const rootPath = `${__PATH_PREFIX__}/`
//   const isRootPath = location.pathname === rootPath
//   let header

//   if (isRootPath) {
//     header = (
//       <h1 className="main-heading">
//         <Link to="/">{title}</Link>
//       </h1>
//     )
//   } else {
//     header = (
//       <Link className="header-link-home" to="/">
//         {title}
//       </Link>
//     )
//   }

//   return (
//     <div className="global-wrapper" data-is-root-path={isRootPath}>
//       {/* <header className="global-header">{header}</header> */}
//       <Header/>
//       <main>{children}</main>
//       <Footer/>
//     </div>
//   )
// }
